/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const SidebarContent = props => {
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>

            <li>
              <NavLink
                to="/promotions"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-conversation"></i>
                <span>推廣橫額</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/items"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-football"></i>
                <span>物品或活動</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/comments"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-star"></i>
                <span>留言及評分</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/take-down"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-archive-arrow-down"></i>
                <span>移除申請</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user"></i>
                <span>會員</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admins"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user-circle"></i>
                <span>管理員</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;

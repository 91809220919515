import React, { useState } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import { Label } from 'reactstrap';
import { LocalFile } from 'app/models';
import { getImageURL } from 'app/helpers/CommonHelper';
import { formatBytes } from 'app/helpers/FormHelper';
import { IMAGE_SIZE } from 'app/config';
import Lightbox from 'react-image-lightbox';
import ImageItem from './ImageItem';
import 'react-image-lightbox/style.css';
import './form.scss';

interface OtherProps {
  label?: string;
  multiple?: boolean;
  sortable?: boolean;
  disabled?: boolean;
}

const ImageField = ({
  label,
  multiple,
  sortable,
  disabled,
  ...props
}: OtherProps & FieldHookConfig<LocalFile[] | null>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const [previewVisible, setPreviewVisible] = useState<string | null>(null);
  const onDelete = index => {
    const temp = [...field.value!];
    temp.splice(index, 1);
    if (temp.length > 0) {
      helper.setValue(temp);
    } else {
      helper.setValue(null);
    }
  };

  const onDrop = files => {
    let images: LocalFile[] = [];
    if (field.value) {
      images = [...field.value];
    }
    files.forEach(file => {
      if (file.size > IMAGE_SIZE) {
        toast.warning('通告圖片不可大於10MB');
      }
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png'
      ) {
        file['preview'] = URL.createObjectURL(file);
        file['formattedSize'] = formatBytes(file.size);
        if (multiple) {
          images.push(file);
        } else {
          images = [file];
        }
        helper.setValue(images);
      } else {
        toast.warning('圖片只接受.jpg / .png檔案');
      }
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const temp = arrayMove(field.value!, oldIndex, newIndex);
    helper.setValue(temp);
  };

  const SortableItem = SortableElement(({ i, file }) => (
    <ImageItem
      imageOnPress={() =>
        setPreviewVisible(
          file.size ? file.preview : getImageURL(file.preview, 'original'),
        )
      }
      onDelete={() => onDelete(i)}
      file={file}
      sortable={sortable}
    />
  ));

  const SortableList = SortableContainer(({ items }) => {
    if (items && items.length > 0) {
      return (
        <div className="dropzone-previews mt-3" id="file-previews">
          {items.map((file, i) => {
            return (
              <SortableItem key={`item-${i}`} index={i} i={i} file={file} />
            );
          })}
          <div className="text-muted mt-2">*排序: 長按再拖動把圖片</div>
        </div>
      );
    }
    return null;
  });
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{label}</Label> : null}
      {disabled ? (
        field.value && field.value.length === 0 ? (
          <h5 className="text-dark font-size-14 mb-3">- 暫無圖片 -</h5>
        ) : null
      ) : (
        <Dropzone
          disabled={disabled}
          multiple={multiple ? true : false}
          onDrop={acceptedFiles => {
            onDrop(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>點擊或拉動圖片到此處</h4>
              </div>
            </div>
          )}
        </Dropzone>
      )}

      {sortable ? (
        <SortableList
          items={field.value}
          onSortEnd={onSortEnd}
          pressThreshold={20}
          pressDelay={200}
        />
      ) : field.value ? (
        field.value.map((file, i) => (
          <ImageItem
            key={i}
            sortable={sortable}
            imageOnPress={() =>
              setPreviewVisible(
                file.size
                  ? file.preview
                  : getImageURL(file.preview, 'original'),
              )
            }
            disabled={disabled}
            onDelete={() => onDelete(i)}
            file={file}
          />
        ))
      ) : null}

      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      {previewVisible ? (
        <Lightbox
          mainSrc={previewVisible}
          onCloseRequest={() => {
            setPreviewVisible(null);
          }}
        />
      ) : null}
    </div>
  );
};

export default ImageField;

/**
 *
 * Asynchronously loads the component for CommentListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CommentListPage = lazyLoad(
  () => import('./index'),
  module => module.CommentListPage,
);

import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';

import Select from 'react-select';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  placeholder?: string;
  options: OptionItems[];
  inputValue?: string;
  onChange?: (item: any) => void;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onInputChange?: (e) => void;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  isSearchable?: boolean;
  isClearable?: boolean;
}

const AutoCompleteSelectField = (
  props: OtherProps & FieldHookConfig<string>,
) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    inputValue,
    onChange,
    onMenuScrollToBottom,
    onMenuClose,
    onInputChange,
    disabled,
    menuPlacement,
    isSearchable,
    isClearable,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return null;
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        {...field}
        value={rendervalue()}
        onChange={e => {
          helpers.setValue(e.value);
          if (onChange) {
            onChange(e);
          }
        }}
        isSearchable={isSearchable}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onMenuClose={onMenuClose}
        captureMenuScroll={true}
        onInputChange={onInputChange}
        inputValue={inputValue}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        isClearable={isClearable}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default AutoCompleteSelectField;

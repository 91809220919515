import { APP_NAME } from 'app/config';
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const TermsPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>「東華三院「借嘢」應用程式及「借嘢」實體店 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>東華三院「借嘢」應用程式及「借嘢」實體店</h4>
                            <h4>免責聲明、版權條款、私隱條例</h4>
                            {/* <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i>{' '}
                              更新於2021年9月1日
                            </p> */}
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <h5 className="mb-3">1、 計劃簡介</h5>
                              <p className="mb-4">
                                「借嘢」為東華三院社會服務科⾸創共享資源互助平台，以「共享經濟」概念為基礎，鼓勵各位把剩餘及閒置資源分配給有需要的人，發揮資源本身的價值，同時讓大家反思人、物品與社區的關係，並藉以建立地區支援，連繫社區。
                              </p>

                              <h5 className="mb-3">2、 適用範圍</h5>
                              <p className="mb-4">
                                本聲明及條款適用範圍包括「借嘢」應用程式及「借嘢」實體店，地址為大角咀菩提街1號地下1B號舖。當閣下同意成為「借嘢」會員，包括登記使用「借嘢」應用程式或於「借嘢」實體店登記成為會員，亦即已明白及同意下列條文。
                              </p>

                              <h5 className="mb-3">3、 免責聲明</h5>
                              <p className="mb-4">
                                「借嘢」應用程式所載及「借嘢」實體店提供的所有個人資料、資訊、刊登的物品、服務、視頻和媒體的剪輯檔案，是在「按現狀」及「按可提供」的情況下提供，並僅作為參考用途。
                              </p>
                              <p className="mb-4">
                                所有會員在使用該等特許資料或服務前先閱讀該等適用的使用條款、免責聲明和私隱政策。「借嘢」應用程式及「借嘢」實體店提供的超連結和連接至由第三方(其他用戶者)維持及/或擁有的網站（「第三方網站」），會不時提供資料或(及)物品或(及)服務。東華三院均不會管制第三方網站，或就所連接的第三方網站內容或其所載的連結負上任何責任。如會員透過「借嘢」應用程式的超連結進入第三方網站，請在使用該第三方網站前先閱讀適用於該第三方網站的使用條款和私隱政策。
                              </p>

                              <p className="mb-4">
                                「借嘢」應用程式及「借嘢」實體店為共享資源互助平台，並開放予所有會員無償分享物品/技能/資訊/服務，東華三院禁止任何會員透過「借嘢」應用程式及「借嘢」實體店進行任何銷售或交易，並禁止任何形式的利益回報行為，包括但不限於金錢、饋贈、貸款、合約、服務等。
                              </p>

                              <p className="mb-4">
                                「借嘢」應用程式及「借嘢」實體店所上載資料由所有會員自行撰寫，東華三院不會就「借嘢」應用程式及/或「借嘢」實體店提供的物品及服務的任何部分作出任何保證，任何透過「借嘢」應用程式或在「借嘢」實體店借出的物品/借用或歸還的物品/接收的服務/饋贈/物品，東華三院一律不保證當中的物品/服務的完整性，或其中任何部分是沒有瑕疵，借用過程所產生的物品破舊、折損、所有風險及責任，必須由服務/物品的提供方及接收方自行承擔。
                              </p>

                              <p className="mb-4">
                                東華三院並不會就任何會員存取及/或使用及/或借出及/或饋贈該等物品/服務，導致使用者蒙受任何損害、損失、法律責任、費用或支出負上任何責任。
                              </p>

                              <p className="mb-4">
                                東華三院建議所有會員對透過「借嘢」應用程式及「借嘢」實體店所接收的第三方訊息需要謹慎處理及查證。東華三院不會就任何載於「借嘢」應用程式內的資訊和刊登的物品或服務的資料之準確性、正確性、適時性、可靠性、品質、適合性及完整性負上任何責任。在任何情況下，東華三院不會就任何會員因「借嘢」應用程式所載或在「借嘢」實體店的物品及服務品質之錯漏及/或虛假聲明或與之有關所招致及/或遭受的任何申索、損失或損害負上任何責任。
                              </p>

                              <p className="mb-4">
                                東華三院將致力確保「借嘢」實體店所提供的物品的安全性，惟若會員因不當使用物品所造成傷害，東華三院亦不會因此承擔任何責任。
                              </p>

                              <h5 className="mb-3">4、 版權條款</h5>

                              <p className="mb-4">
                                版權除另有聲明外，「借嘢」應用程式及「借嘢」實體店所載的所有材料，均屬東華三院的知識財產。所有資訊均受到香港特別行政區及/或其他司法管轄區的版權法和知識產權法保障。在未取得東華三院事先書面同意前，所有會員不得以任何方式全部或部分複製或轉發任何材料。
                              </p>

                              <h5 className="mb-3">5、 特別聲明</h5>
                              <p className="mb-4">
                                東華三院有權終止任何會員使用「借嘢」應用程式及「借嘢」實體店服務，如有任何爭議，東華三院將保留最終決定權。
                              </p>
                              <p className="mb-4">
                                本免責聲明中概述的條款和條件可以更改和更新，恕不另行通知；請各會員定期查看免責聲明。
                              </p>

                              <h5 className="mb-3">6、 私隱條例</h5>
                              <p className="mb-3">
                                東華三院會盡力遵守《個人資料(私隱)條例》中所列載的規定，確保儲存的個人資料處理得到充份保護，及有妥善的儲存。為確保所有會員能充分了解向東華三院提供個人資料的準則，請細閱本聲明。
                              </p>
                              <h5 className="mb-3">
                                收集及儲存資料目的及使用準則
                              </h5>
                              <ul>
                                <li>
                                  東華三院可能使用會員的個人資料(包括但不限於姓名、電話、傳真、電郵、郵寄地址、所屬機構/公司/學校)，以便東華三院日後與會員通訊、處理報名、發行收據、研究/分析/統計、籌款、收集意見、作活動/訓練課程邀請/推廣用途﹔
                                </li>
                                <li>
                                  所有會員提供的個人資料，會供東華院在工作上有需要知道該等資料的職員使用。除此之外，東華三院職員在需要時亦只會向下列有關方面披露該等資料：
                                  <ul>
                                    <li>
                                      其他涉及評定該會員的申請或服務提供，或向該會員提供服務的有關方面，例如政府部門、非政府機構、學校、醫院及公用事業公司；或
                                    </li>
                                    <li>
                                      該會員曾同意向其披露資料的人士/機構；或
                                    </li>
                                    <li>
                                      由法律授權或法律規定須向其披露資料的人士/機構。
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  向東華三院提供個人資料純屬自願，如未能提供足夠個人資料，東華三院可能無法有效處理有關會員申請或提供有關服務﹔
                                </li>
                                <li>
                                  所有會員亦可以隨時電郵至
                                  <a href="mailto: fortunelibrary@tungwah.org.hk">
                                    fortunelibrary@tungwah.org.hk
                                  </a>
                                  要求東華三院停止使用該會員的個人資料作上述之用途﹔
                                </li>
                                <li>
                                  根據《個人資料(私隱)條例》（第486章），所有會員均有權查閱及更正個人資料。有關索閱及更正個人資料要求，請發送電郵至
                                  <a href="mailto: fortunelibrary@tungwah.org.hk">
                                    fortunelibrary@tungwah.org.hk
                                  </a>
                                  。
                                </li>
                              </ul>
                              <p className="mb-4">
                                本人已閱讀及同意本《免責聲明、版權條款、私隱條例》所載內容。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsPage;

import { Filter, ListResult } from 'app/models';
import Axios from '.';
import { TakeDown, TakeDownData } from 'app/models';

export const createTakeDown = async (params: TakeDown) => {
  const { data } = await Axios.post<any>(`/message`, params);
  return data;
};
export interface GetTakeDownListParams extends Filter {}

export const getTakeDownList = async (params: GetTakeDownListParams) => {
  const { data } = await Axios.get<ListResult<TakeDownData>>('/message', {
    params,
  });
  return data;
};

export const getOneTakeDown = async (takedownId: string | number) => {
  const { data } = await Axios.get<TakeDownData>(`/message/${takedownId}`);
  return data;
};

import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import user1 from 'app/assets/images/users/avatar.png';
import Dialog from 'app/components/Modal/Modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'app/components/Form';
import { sendNotification } from 'app/services/AdminServices';
import { toast } from 'react-toastify';

const Schema = Yup.object().shape({
  title: Yup.string()
    .min(2, '不能少於2個字元')
    .max(100, '不能多於100個字元')
    .required('必填項目'),
});

interface FormItem {
  title: string;
}

const FORM_ITEM = {
  title: '',
};

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser') as string);
      setusername(obj.nick);
    }
  }, []);

  const onSubmit = async (values: FormItem) => {
    setLoading(true);
    try {
      await sendNotification(values);
      toast.success(`成功發送推送訊息。`);
      setLoading(false);
      setFormVisible(false);
      setForm(FORM_ITEM);
    } catch (err) {
      toast.warning(`發送推送訊息失敗，請重試。`);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Button
            to="/logout"
            className="dropdown-item"
            onClick={() => setFormVisible(true)}
          >
            <i className="bx bx-info-circle font-size-16 align-middle me-1 text-info" />
            <span className="text-dark">推送訊息</span>
          </Button>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Dialog
        visible={formVisible}
        title="推送訊息"
        onClose={() => setFormVisible(false)}
      >
        <Formik
          initialValues={form}
          validationSchema={Schema}
          onSubmit={onSubmit}
        >
          {({ errors }) => (
            <Form className="form-horizontal" style={{ width: '100%' }}>
              <Row>
                <Col lg={12} sm={12} xs={12}>
                  <InputField
                    name="title"
                    label="推送訊息"
                    placeholder="輸入推送訊息"
                  />
                  <div className="text-muted">
                    *所有手機用戶將會收到以上推送訊息。
                  </div>
                </Col>
              </Row>
              <div className="mt-3 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  color="primary"
                  className="ms-1"
                  disabled={loading}
                >
                  {loading ? (
                    <i className="bx bx-loader-circle bx-spin" />
                  ) : (
                    '提交'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileMenu;

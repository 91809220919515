import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row, Button } from 'reactstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'app/components/Form';
import { createTakeDown } from 'app/services/TakeDownServices';
import { toast } from 'react-toastify';

// form

// form schema
const Schema = Yup.object().shape({
  contactName: Yup.string()
    .nullable()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .required('必填'),
  email: Yup.string().email('電子郵件格式錯誤').nullable().required('必填'),
  phone: Yup.string()
    .nullable()
    .matches(/^[2-9]\d{7}$/g, '電話號碼格式錯誤')
    .required('必填'),
  message: Yup.string()
    .min(1, '最少要1個字元')
    .max(10000, '不可多於10,000個字元')
    .required('必填'),
});

// form type
type FormItem = {
  contactName: string;
  email: string;
  phone: string;
  message: string;
  isReplyRequired: boolean;
};

// form initialValues
const FORM_ITEM: FormItem = {
  message: '',
  contactName: '',
  phone: '',
  email: '',
  isReplyRequired: false,
};

export const TakeDownPage = () => {
  const [form] = useState<FormItem>(FORM_ITEM);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (
    values: FormItem,
    action: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    try {
      await createTakeDown(values);
      action.resetForm();
      toast.success('提交成功，我們會盡快處理。');
    } catch (err) {
      toast.error('提交失敗，請重試。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>移除政策</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>移除政策</h4>
                          </div>
                          <div className="mt-4">
                            <h5>從本應用程式移除內容</h5>
                            <div className="text-muted font-size-14">
                              <p>
                                請提交下列表格，向我們檢舉涉嫌違反相關法律的服務內容。請提供完整資訊，協助我們針對您提出的問題展開調查。
                              </p>

                              <p>
                                本會可能會將原始通知寄給受指控的侵權者，如果我們合理懷疑您提出的申訴有所不實，則會將這份通知提交給版權所有人。
                              </p>
                              <p>
                                請注意，如果您提供的資料不正確或不完整，我們不會予以處理。
                              </p>
                            </div>
                          </div>

                          <div>
                            <Formik
                              initialValues={form}
                              validationSchema={Schema}
                              onSubmit={onSubmit}
                            >
                              {() => (
                                <Form className="form-horizontal">
                                  <Row className="mt-2">
                                    <hr />
                                    <Col md={12}>
                                      <InputField
                                        name="contactName"
                                        label="姓名"
                                        placeholder="姓名"
                                        type="text"
                                      />
                                    </Col>

                                    <Col md={12}>
                                      <InputField
                                        name="email"
                                        label="電郵地址"
                                        placeholder="電郵地址"
                                        type="email"
                                      />
                                    </Col>

                                    <Col md={12}>
                                      <InputField
                                        name="phone"
                                        label="聯絡電話"
                                        placeholder="聯絡電話"
                                        type="text"
                                      />
                                    </Col>
                                    <Col md={12}>
                                      <InputField
                                        name="message"
                                        label="內容詳情"
                                        placeholder="內容詳情"
                                        type="textarea"
                                      />
                                    </Col>
                                  </Row>

                                  <div className="mt-3 d-flex flex-row-reverse">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="ms-1"
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <i className="bx bx-loader-circle bx-spin" />
                                      ) : (
                                        '提交'
                                      )}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';

import Select from 'react-select';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: () => void;
  isClearable?: boolean;
  disabled?: boolean;
}

const SelectField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return {};
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        // value={selectedMulti}
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        // formatOptionLabel={option => option.label}
        classNamePrefix="select2-selection"
        {...field}
        value={rendervalue()}
        onChange={e => {
          if (e) {
            helpers.setValue(e.value);
          } else {
            helpers.setValue('');
          }
          if (onChange) {
            onChange();
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default SelectField;

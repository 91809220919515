import { getImageURL } from 'app/helpers/CommonHelper';
import { LocalFile } from 'app/models';
import React from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

interface ImageItemProps {
  imageOnPress: () => void;
  file: LocalFile;
  onDelete: () => void;
  sortable?: boolean;
  disabled?: boolean;
}

const ImageItem = ({
  imageOnPress,
  file,
  onDelete,
  sortable,
  disabled,
}: ImageItemProps) => {
  return (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      <div className="p-2">
        <Row className="align-items-start">
          {sortable ? (
            <Col className="col-auto">
              <div
                className="d-flex flex-column align-items-center justify-content-center font-size-20"
                style={{ height: '80px', cursor: 'grab' }}
              >
                <i className="bx bx-menu" />
              </div>
            </Col>
          ) : null}
          <Col className="col-auto">
            <img
              onClick={() => imageOnPress()}
              data-dz-thumbnail=""
              height="80px"
              className="rounded bg-light preview-image"
              alt={file.name || ''}
              src={file.size ? file.preview : getImageURL(file.preview)}
            />
          </Col>
          <Col>
            <Link to="#" className="text-muted font-weight-bold">
              {file.name}
            </Link>
            <p className="mb-0">
              <strong>{file.formattedSize}</strong>
            </p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              color="danger"
              onClick={() => onDelete()}
              disabled={disabled}
            >
              <i className="bx bx-trash-alt" />
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ImageItem;
